import React, { FC } from 'react';
import { IQuestionCardProps } from '@shared/types';
import './QuestionCard.scss';
import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { Link } from 'gatsby';
import Icon from '../../../common/IconCustom';

const QuestionCard: FC<IQuestionCardProps> = ({
  title,
  image,
  link,
  description,
  isVerticalCardMode,
}) => {
  const previewImage = image?.[0]?.properties?.image;

  return isVerticalCardMode ? (
    <div className="question-card-vertical">
      {image?.[0]?.properties && (
        <GatsbyImage
          className="question-card-vertical__image"
          image={previewImage}
          alt={image?.[0]?.properties?.imageAlt}
          useFocalPoint
        />
      )}
      {title && <h3 className="question-card-vertical__title">{title.toUpperCase()}</h3>}
      {description && (
        <DangerouslySetInnerHtml
          className="question-card-vertical__description"
          html={description}
        />
      )}
    </div>
  ) : (
    <Link className="question-card" to={link[0]?.url}>
      {title ? (
        <div className="question-card__title">
          <div className="question-card__title-text">{title?.toUpperCase()}</div>
          <div className="question-card__title__icon">
            <Icon icon="chevron-right" />
          </div>
        </div>
      ) : null}
      <GatsbyImage
        className="question-card__image"
        image={previewImage}
        alt={image?.[0]?.properties?.imageAlt}
        role="presentation"
      />
    </Link>
  );
};

export default QuestionCard;
