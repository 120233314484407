import React, { FC, useEffect, useState } from 'react';

import './QuestionsCarousel.scss';
import classnames from 'classnames';
import QuestionCard from '../QuestionCard';
import { QuestionsCarouselProps } from './model';
import { isBrowser } from '../../../utils/browser';
import BlockCarousel from '../../BlockCarousel';
import PrevArrow from '../../PDCarousel/components/prevArrow';
import NextArrow from '../../PDCarousel/components/nextArrow';

const QuestionsCarousel: FC<QuestionsCarouselProps> = ({
  questionCards = [],
  ariaNext,
  ariaPrev,
  customCardsComponent,
  isVerticalCardMode,
}) => {
  const slideValue = isVerticalCardMode ? 3 : 4;
  const [slidesToShow, setSlidesToShow] = useState<number>(slideValue);

  const [innerWidth, setInnerWidth] = useState<number>(0);

  const calculateSlidesToShow = () => {
    const questionCardsLength = questionCards.length;
    if (innerWidth <= 600) {
      return 1;
    }
    if (innerWidth <= 1170) {
      return 2;
    }

    return questionCardsLength < slideValue ? questionCardsLength : slideValue;
  };

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', () => setInnerWidth(window.innerWidth));
    }
    setInnerWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', () => setInnerWidth(window.innerWidth));
    };
  }, [isBrowser()]);

  useEffect(() => {
    setSlidesToShow(calculateSlidesToShow());
  }, [innerWidth, JSON.stringify(questionCards)]);

  const settings = {
    slidesToShow,
    slidesToScroll: isVerticalCardMode ? 3 : 4,
    dots: true,
    customPaging: () => <span className="btn btn--icon" />,
    prevArrow: <PrevArrow ariaLabel={ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={ariaNext} />,
    responsive: [
      {
        breakpoint: 1170,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {questionCards?.length ? (
        <div
          className={classnames('questions-carousel-container', {
            [`questions-carousel-container--slides-${slidesToShow}`]: slidesToShow,
            [`questions-carousel-container--vertical-mode-slides-${slidesToShow}`]:
              isVerticalCardMode,
          })}
        >
          <BlockCarousel settings={settings}>
            {customCardsComponent ||
              questionCards.map((item) => {
                const { title, image, link, description } = item;

                return (
                  <QuestionCard
                    key={item.title}
                    title={title}
                    image={image}
                    link={link}
                    description={description}
                    isVerticalCardMode={isVerticalCardMode}
                  />
                );
              })}
          </BlockCarousel>
        </div>
      ) : null}
    </>
  );
};

export default QuestionsCarousel;
